import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import logo from "../images/picto_404.png"

const NotFoundPage = ({ location }) => (
  <Layout ariane={[{title: "Accueil", url: "/"}, {title: "Page Introuvable", url:"#"}]}>
    <SEO titleSEO="Page introuvable" />
    <div className="content-404">
      <div className="container">
        <img src={logo} alt="logo boussole" className="img-fluid d-block mx-auto" />
        <div className="text mx-auto">
          <h1><span className="asap-semi-bold">Oups !</span> Il semblerait que vous ayez perdu le <span className="asap-semi-bold">FIL</span>...</h1>
          <p>Heureusement, même d’ici vous pouvez <a href="/">retrouver la page d’accueil !</a></p>
        </div>
      </div>
    </div>
  </Layout >
)

export default NotFoundPage
